/* Skeleton.css */

.ske_row {
    /* background-color: rgb(240, 240, 240); */
    padding: 0.7rem 0.4rem 0 !important;
    margin-bottom: 1rem !important;
}

.ske_col {
    padding: 0rem .3rem !important;
    margin-bottom: .6rem !important;
}

.skeleton-main {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    padding-top: 2rem;
}

.skeleton-card {
    display: flex;
    /* border: 1px solid #ccc; */
    flex-direction: column;
    /* margin: 10px;
    padding: 10px; */
    /* width: 14.2%; */
}

.skeleton-image {
    width: 100%;
    height: 400px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    margin-bottom: .8rem;
}

.skeleton-details {
    flex: 1;
    /* padding-left: 10px; */
}

.skeleton-title {
    width: 100%;
    height: 55px;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

.skeleton-description {
    width: 100%;
    height: 12px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}