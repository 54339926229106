.categories-main {
    /* background-color: rgb(238, 238, 238);
    height: 92vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
}

.category_main_card {
    border: none!important;
    background-color: transparent !important;
}

.category_header {
    border: 1px solid #D6D6D6;
    border-radius: 0 !important;
}

.cat_img_sec {
    height: 50px !important;
    width: 60px !important;
}

.cate_sub_title {
    padding: 1rem;
    color: #8d8a8a;
    margin-bottom: 3rem;
}

.category_add_link {
    color: #ee3a4b;
    text-decoration: none;
    margin-left: 10px;
    font-size: 19px;
    font-weight: 500;
}

.cat-header {
    background-color: transparent !important;
    border: none !important;
}

.cat-header p {
    float: left !important;
    background-color: #b05db1 !important;
    color: white;
    padding: 1.2rem;
    position: relative;
}

.cat-header p svg {
    font-size: 30px;
}

.jNvkxB {
    font-size: 18px !important;
}

.iWyFZZ div {
    font-size: 15px !important;
}


/* .img-cat {
    position: absolute;
    top: -50px;
} */

.cat_thead tr th {
    font-size: 16px;
    font-weight: 400;
    color: #FFF;
    background-color: #625c5c;
}

.categories-tbody tr td {
    padding: .5rem!important;
    font-size: 15px;
}

.categories-tbody tr td:first-child {
    padding-left: 1rem !important;
}

.active_status {
    border: 1px solid #008000;
    background: rgba(219, 238, 219, 0.70);
    padding: .2rem .5rem;
    font-size: 14px;
    color: #008000;
    border-radius: 4px;
    margin-bottom: 0;
}

.dective_status {
    border-radius: 4px;
    border: 1px solid #ee3a4b;
    background: #ffe7e9;
    padding: .2rem .5rem;
    color: #ee3a4b !important;
}

.cat-title-img-g {
    background-color: #FFF !important;
    padding: 1rem !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* .cat_action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
} */


/* 
.edit-icon {
    background-color: #4caf50;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
}

.delete-icon {
    background-color: rgb(255, 71, 71);
    height: 30px;
    width: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
} */

.search-input-btn-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-input-cat {
    position: relative;
}

.search-icon-cat {
    position: absolute;
    right: 4%;
    top: 19%;
}

.search-input-cat .categories_search_input {
    /* border: none; */
    border-radius: 5px !important;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: #FDFDFD;
    padding: .3rem;
    outline: none;
}

.fEnJOm {
    background-color: #372036 !important;
    min-height: 41px !important;
}

.jNvkxB {
    color: #FFF !important;
    font-size: 16px !important;
}

.fssVbF,
.elnqKc {
    color: #FFF !important;
}


/* 
@media (max-width:1400px) {
    .cat_action {
        width: 60%;
    }
} */