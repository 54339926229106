/* MyComponent.module.css */


/* MyComponent.module.css */

.add_products {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}

.add_products_heading {
    /* height: 100%;
    width: 700px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px; */
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    height: 100%;
    margin: 0 51px 10px;
    width: 100%;
}

.form_padding {
    padding: 20px;
}

.heading_add {
    font-size: 18px;
    font-family: "Poppins";
}

.main_heading {
    display: flex;
    gap: 20px;
    background: #ee3a4b;
    color: white;
    padding: 19px;
}

.product_tilte {
    margin-top: 20px;
}

.product_tilte input,
select {
    border-radius: 0px !important;
}

.title_heading {
    font-size: 16px;
    font-family: "Poppins";
}


/* MyComponent.module.css */

.custom_select {
    position: relative;
    /* Create a positioning context for the pseudo-element */
    margin-top: 20px;
}

.custom_select::after {
    content: '\25BC';
    /* Unicode arrow-down character */
    position: absolute;
    top: 73%;
    right: 10px;
    /* Adjust the position as needed */
    transform: translateY(-50%);
    pointer-events: none;
    /* Allow clicks to pass through to the select element */
}

.rem_btn {
    background-color: #e99f1e !important;
    color: #FFF !important;
    border-radius: 20px !important;
    margin-left: 15px;
}

.change_btn {
    background-color: #ee3a4b !important;
    color: #FFF !important;
    border-radius: 20px !important;
    margin-left: 15px;
}

.product_add_btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btn_add {
    background-color: #ee3a4b !important;
    color: white !important;
    border-radius: 0px !important;
    padding: 8px 20px !important;
}

.product_add_one_line {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
}

.form_padding select {
    background-color: #FFF !important;
}

.select_img_sec {
    height: 200px;
    width: 200px;
    margin: .6rem 0;
    object-fit: cover;
    background-position: center;
    background-size: cover;
}

.select_img_sec img {
    height: 100%;
    width: 100%;
}

.error_text {
    color: #ee3a4b;
}