/* MyComponent.module.css */


/* MyComponent.module.css */

.add_products {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}

.add_products_heading {
    /* height: 100%;
    width: 825px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    background: #FAFAFA; */
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    height: 100%;
    margin: 0 51px 10px;
    width: 100%;
}

.form_padding {
    padding: 10px 20px 30px 20px;
}

.main_body_table tr th input,
.main_body_table tr td input {
    text-align: center !important;
}

.heading_add {
    font-size: 18px;
    font-family: "Poppins";
}

.product_name_input {
    border: none !important;
    border-bottom: 1px solid #ddd !important;
    background-color: #FAFAFA;
}

.main_heading {
    display: flex;
    gap: 20px;
    background: #dc3545;
    color: white;
    padding: 19px;
}

.product_tilte {
    margin-top: 20px;
}

.title_heading {
    font-size: 16px;
    font-family: "Poppins";
}


/* MyComponent.module.css */

.product_tilte input,
select {
    border-radius: 0px !important;
    background-color: transparent !important;
}

.product_add_btn {
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btn_add {
    background-color: #dc3545 !important;
    color: white !important;
}

.btn_add1 {
    background-color: #008000 !important;
    color: white !important;
}

.input_div_product {
    margin-bottom: 20px;
    background-color: white;
}