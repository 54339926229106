.home-main {
    background-color: #EEE;
    height: 100%;
    padding-top: 1rem;
}

.home_c_card {
    border: none !important;
    height: 100% !important;
}

.home_card_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .5rem 1rem .6rem 1.5rem!important;
    background: #FFF !important;
    border-bottom: 1px solid rgba(217, 217, 217, 0.42) !important;
}

.home-icon-g {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: #FD9811;
    font-family: 'Poppins', sans-serif;
}

.home-icon-info {
    margin-top: 1rem;
    margin-left: 1rem;
}

.icon-info-para {
    color: #454545;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    text-transform: capitalize;
}

.icon-info-t {
    color: #FD9811;
    font-family: sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.home_card_body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.home_b_info {
    margin-left: 5px;
}

.b_info_p1 {
    color: #636363;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
}

.b_info_p2 {
    color: #372036;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
    font-family: sans-serif;
    margin-top: 0.1rem;
}


/* second card */

.home-icon-g-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: #DD2467;
    font-family: 'Poppins', sans-serif;
}

.icon-info-t-2 {
    color: #DD2467;
    font-family: sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}


/* third card */

.home-icon-g-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: #47A34B;
    font-family: 'Poppins', sans-serif;
}

.icon-info-t-3 {
    color: #47A34B;
    font-family: sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}


/* fourth card */

.home-icon-g-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: #0EB6CA;
    font-family: 'Poppins', sans-serif;
}

.icon-info-t-4 {
    color: #0EB6CA;
    font-family: sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}


/* pravin css */

.home_table_card {
    border: none!important;
    border-radius: 0px !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
}


/* 
.home_table_thead,
tr,
th {
    border: none !important;
} */

.home_table_main,
tr,
thead,
tbody,
td,
th {
    border: none !important;
}

.today_order_table {
    border: 1px solid #D6D6D6;
    background: #FFF;
    margin-top: 10px;
    padding: 13px 0px 10px 30px;
}

.cart_heading {
    display: flex;
}

.today_heading {
    color: #210720;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
    margin-top: 5px;
}

.show_entry_heading {
    color: #868686;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
    margin-left: 15px;
    text-transform: capitalize;
}

.show_entreis {
    border-radius: 3px;
    border: 1px solid #D6D6D6;
    background: #F8F8F8;
    height: 30px;
    /* width: 30px; */
    padding: 2px 9px 0px 8px;
    margin-top: 3px;
    margin-left: 20px;
}

.search_icon1 {
    position: absolute;
    right: 10px;
    /* Adjust this value to control the icon's right alignment */
    top: 50%;
    transform: translateY(-50%);
    color: #555;
    /* Icon color */
    cursor: pointer;
}

.view_list_heading {
    color: #dc3545;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.search_input1 {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background: #FDFDFD;
}

.search_icon2 {
    width: 91px !important;
}

.today_order_Search {
    position: relative;
    display: inline-block;
}

.search_icon3 {
    position: absolute;
    right: 10px;
    /* Adjust this value to control the icon's right alignment */
    top: 50%;
    transform: translateY(-50%);
    color: #555;
    /* Icon color */
    cursor: pointer;
}

.view_list_kanban {
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #dc3545;
    background: #ffd8db;
    height: 38px !important;
    border-right: none;
    width: 80px;
    padding: 8px 0px 0px 10px;
    cursor: pointer;
}

.view_list {
    border-radius: 5px 0px 0px 5px;
    border: 1px solid #dc3545;
    border-right: none;
    background: #ffd8db;
    height: 38px !important;
    width: 80px;
    padding: 8px 0px 0px 10px;
    cursor: pointer;
}

.view_list1 {
    border-radius: 0px 5px 5px 0px;
    border: 1px solid #d6d6d6;
    background: #ffffff;
    height: 38px !important;
    width: 80px;
    padding: 8px 0px 0px 10px;
    cursor: pointer;
}

.view_list2 {
    border-radius: 5px 0px 0px 5px;
    border: 1px solid #d6d6d6;
    background: #ffffff;
    height: 38px !important;
    width: 80px;
    padding: 8px 0px 0px 10px;
    cursor: pointer;
}

.view_list_heading1 {
    color: #9A9A9A;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.kanban_input {
    width: 50px !important;
}

.view_input {
    display: flex;
    margin-left: 10px;
    margin-right: 20px;
}

.table-striped {
    border: 1px solid #dddd;
}

.table-striped {
    text-align: center;
    color: #3B3B3B !important;
    font-family: 'Poppins' !important;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* text-transform: capitalize; */
}

.main_table_head th {
    background-color: #625c5c !important;
    color: #FFF !important;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
}

.padding_bt {
    padding-bottom: 1.2rem !important;
}

.view_address {
    border-radius: 2px;
    border: 0.9px solid #0094FF;
    color: #0094FF;
    padding: 0px 8px;
    cursor: pointer;
    /* margin-left: 55px; */
}

.confirm_status {
    border-radius: 4px;
    border: 1px solid #008000;
    background: rgba(219, 238, 219, 0.70);
    padding: 0px 8px;
    color: #008000 !important;
}

.cancel_status {
    border-radius: 4px;
    border: 1px solid #ee3a4b;
    background: #ffe7e9;
    padding: 0px 8px;
    color: #ee3a4b !important;
    cursor: text !important;
}

.pending_status {
    border-radius: 4px;
    border: 1px solid #a9a9a9;
    background: #ebebeb;
    padding: 0px 8px;
    color: #6b6b6b !important;
    cursor: text !important;
}

.dispatch_status {
    border-radius: 4px;
    border: 1px solid #04afad;
    background: #dff6fa;
    padding: 0px 8px;
    color: #04afad !important;
    cursor: text !important;
}

.custom_variant {
    font-size: 14px !important;
}

.complete_status {
    border-radius: 4px;
    border: 1px solid #0a9c02;
    background: rgba(228, 250, 228, 0.7);
    padding: 0px 8px;
    color: #0a9c02 !important;
    cursor: text !important;
}

.confirm_img {
    height: 25px !important;
}

.sortable_order {
    display: flex;
    align-items: center;
}

.icons_sorting {
    margin: 0px !important;
    padding: 0px !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #ee3a4b !important;
    color: white !important;
}

.pagination_table {
    display: flex;
    justify-content: flex-end;
}

@media (min-width:1300px) and (max-width:1400px) {
    .home-icon-g,
    .home-icon-g-2,
    .home-icon-g-3,
    .home-icon-g-4 {
        width: 50px;
        height: 50px;
    }
    .home-icon-g svg,
    .home-icon-g-2 svg,
    .home-icon-g-3 svg,
    .home-icon-g-4 svg {
        width: 30px;
        height: 30px;
    }
    .icon-info-para {
        font-size: 14px;
    }
    .icon-info-t,
    .icon-info-t-2,
    .icon-info-t-3,
    .icon-info-t-4 {
        font-size: 20px;
    }
    .padding_bt {
        padding-bottom: 0.6rem !important;
    }
    .home_card_body {
        flex-direction: column;
        padding: 0.5rem;
    }
    .b_info_p1 {
        font-size: 13px;
    }
    .b_info_p2 {
        font-size: 14px;
        font-family: auto;
    }
    .b_info_p1_tracked_f_database {
        margin-top: 0rem !important;
    }
}