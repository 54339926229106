/* ImageGrid.module.css */


/* Styles for the grid container */

.container {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 10px;
}


/* Styles for each grid item */

.column {
    /* flex: 0 0 calc(33.333% - 20px); */
    /* Adjust the width as needed */
    margin: 10px;
    /* Adjust the margin as needed */
}


/* Updated Styles for the card */

.card {
    position: relative;
    /* Make the card a positioning context */
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    height: 200px !important;
    width: 200px !important;
}

.card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}


/* Styles for the card image */

.card-img-top {
    object-fit: contain;
    height: 100%;
    /* Adjust the height as needed */
}

.card-img-top1 {
    object-fit: cover;
}


/* Styles for the card body */

.card-body {
    padding: 0px;
}


/* Styles for the product title */

.productTitle {
    font-weight: bold;
    margin-bottom: 10px;
}


/* Styles for the trash icon */

.deleteButton {
    background-color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    color: #ee3a4b !important;
    border: none;
    /* padding: 3px 10px; */
    cursor: pointer;
    transition: color 0.3s ease;
    font-family: poppins;
    font-size: 14px;
    position: absolute;
    top: 10px;
    right: 10px;
}


/* variant css */

.product_name_variant {
    padding: 4px 10px 0px 10px;
    font-size: 13px;
}

.select_img_sec {
    height: 200px;
    width: 200px;
}

.select_multi_img_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.select_multi_img_sub_box {
    text-align: center;
}

.select_img_sec img {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
}

.add_multi_img_input_box {
    /* display: flex;
    align-items: center;
    gap: 2rem; */
    margin-bottom: 2rem;
}

.add_multi_img_input_box input {
    border-radius: 0px !important;
}

.multi_img_box {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.multi_img_box_sub_sec {
    height: 200px;
    width: auto;
    background: #fff;
    position: relative;
}

.multi_img_box_sub_sec img {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
}