.add_cat_main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
}

.add_c_t {
    padding-bottom: 2rem;
    font-weight: 400;
    margin-top: 1px;
}

.form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.form-control:focus {
    box-shadow: none !important;
}

.selected_img_group {
    display: block;
    text-align: center;
    width: 200px;
}


/* .selected_img_group img {
    display: block;
} */

.Add_btn_group {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
}

.add_to_cat {
    background-color: #ee3a4b !important;
    color: #FFF !important;
    border-radius: 3px !important;
    transition: linear 0.5s ease-in-out;
}

.add_to_cat:hover {
    background-color: #FFF !important;
    color: #ee3a4b !important;
    transform: scale(1.1);
    font-weight: 500;
    box-shadow: 0 3px 10px #ee3a4b;
}