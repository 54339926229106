/* MyComponent.module.css */


/* MyComponent.module.css */

.add_products {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}

.add_products_heading {
    height: 100%;
    width: 700px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    /* background: #FAFAFA; */
    /* Add other styles as needed */
}

.heading_add {
    font-size: 18px;
    font-family: "Poppins";
}

.form_padding {
    padding: 20px;
}

.main_heading {
    display: flex;
    gap: 20px;
    background: #ee3a4b;
    color: white;
    padding: 19px;
}

.product_tilte {
    margin-top: 20px;
}

.title_heading {
    font-size: 16px;
    font-family: "Poppins";
}


/* MyComponent.module.css */

.product_add_btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btn_add {
    background-color: #ee3a4b !important;
    color: white !important;
    border-radius: 0px !important;
    padding: 5px 30px !important;
}

.rem_btn {
    background-color: #e99f1e !important;
    color: #FFF !important;
    border-radius: 20px !important;
    margin-left: 15px;
}

.Download_btn {
    background-color: #e99f1e !important;
    color: #FFF !important;
    border-radius: 0px !important;
}

.product_tilte input,
select {
    border-radius: 0px !important;
}