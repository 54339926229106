.edit_offer_card {
    width: 70%;
    padding: 2rem;
    padding-top: 1rem;
    border: none !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.add_banner_sec {
    position: relative;
}

.add_banner_btn {
    border: none !important;
    outline: none !important;
    color: #dc3545 !important;
    font-weight: 600 !important;
}

.add_banner_btn::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #dc3545;
    transform: scaleX(0);
    transition: .5s ease;
}

.table_img_sec {
    height: 50px;
    width: 50px;
}

.table_img_sec img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.add_banner_btn:hover:after {
    transform: scaleX(1.1);
    padding: 0 .5rem;
}

.edit_offer_label_column {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.slider_eclipse {
    max-width: 200px;
    /* Adjust the max-width as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.offer_input {
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #c3c3c3 !important;
}

.remove_btn {
    background-color: #e99f1e !important;
    color: #FFF !important;
    border-radius: 20px !important;
    margin-top: 1rem;
}


/* media  query*/

@media (max-width:1000px) {
    .edit_offer_card {
        width: 100%;
    }
    .edit_offer_label_column {
        justify-content: flex-start;
    }
}