  .main_invoice {
      width: 100%;
      max-width: 900px !important;
      margin: 0 auto;
      padding: 20px;
      font-size: 14px;
  }
  
  .header {
      display: flex;
      justify-content: space-between;
  }
  
  .header img {
      height: 100px;
      width: 100px;
  }
  
  .header .company-info {
      text-align: right;
  }
  
  .invoice_title {
      font-size: 16px;
      text-align: center;
  }
  .table_main {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-bottom: none;
}
  /* .border_left {
      border-left: 1px solid #dddddd;
  } */
  
  .table_main th,
  .table_main td {
      border: 1px solid #000000 !important;
      text-align: left;
      padding: 8px;
      font-family:sans-serif;
      /* width: 45px; */
  }
  .table_main1 th,
  .table_main1 td {
      border: 1px solid #000000;
      border-top: none !important;
      text-align: left;
      padding: 8px;
      /* width: 45px; */
  }
  .table_main2 th,
  .table_main2 td {
      border: 1px solid #000000;
      border-bottom: none !important;
      text-align: left;
      padding: 8px;
      /* width: 45px; */
  }
  
  .invoice_col {
      display: flex;
      gap: 20px;
  }
  /* .table_main th {
    background-color: gray;
    color: white;
    border-left: 1px solid #dddd;
    border-right: 1px solid #dddd !important;
  } */
  
  .table_main p {
      margin-bottom: 0px !important;
      font-family: sans-serif;
  }
  
  .table_main th:nth-child(1),
  .table_main th:nth-child(2),
  .table_main th:nth-child(3),
  .table_main th:nth-child(4) {
      border-right: none;
  }
  
  .table_main th:last-child,
  .table_main td:last-child {
      border-right: 1px solid #000000 !important;
  }
  
  .total_row td {
      font-weight: bold;
  }
  /* .total_row1 td, span {
    font-weight: bold;
  } */
  
  .amount-in-words {
      border-top: 2px solid #dddddd;
      padding: 15px 0;
  }
  
  .terms {
      border-top: none !important;
      padding-top: 20px;
  }
  
  table td.border_none {
      border-top: none !important;
  }
  
  .signature {
      text-align: center;
      padding-top: 20px;
  }
  
  .signature img {
      height: 100px;
      width: 150px;
  }
  
  .invoice_background {
      /* background-color: white !important; */
      color: black !important;
      text-align: center !important;
  }
  
  .invoice_background p {
      color: rgb(0, 0, 0) !important;
      font-weight: 400;
  }
  
  .text_align {
      text-align: center !important;
  }
  
  #height_img {
      margin-left: 10%;
      margin-top: 10px;
  }
  
  .success_msg {
      margin-top: 56px;
  }
  
  .bd_weight {
      font-weight: bold;
  }
  
  .amount_pad {
      padding-top: 0px !important;
      padding-bottom: 43px !important;
  }
  
  .stampp_size {
      margin-left: 59px !important;
  }
  
  #invoice_form {
      padding-top: 0px !important;
      padding-bottom: 43px !important;
  }
  
  #customer_detail {
      padding-top: 0px !important;
      padding-bottom: 67px !important;
  }
  /* Update the Product Name column width */
  
  .width_row td:nth-child(2) {
      width: 72%;
      /* You can adjust the percentage as needed */
  }
  
  .width_row td:nth-child(3) {
      width: 0%;
      /* You can adjust the percentage as needed */
  }
  
  .width_row td:nth-child(4) {
      width: 10%;
      /* You can adjust the percentage as needed */
  }
  /* Reduce the ID column width */
  
  .width_row td:nth-child(1) {
      width: 1%;
      /* You can adjust the percentage as needed */
  }
  
  .tax_row td:nth-child(1) {
      width: 15% !important;
  }
  
  .tax_row td:nth-child(5) {
      width: 33.9% !important;
  }
  
  .tax_row td:nth-child(2) {
      width: 20% !important;
  }
  
  .tax_row td:nth-child(3) {
      width: 20% !important;
  }
  
  .tax_row td:nth-child(4) {
      width: 15% !important;
  }
  
  @media print {
      @page {
          size: auto;
          margin-top: 0mm;
          /* Set your custom top margin, e.g., 10mm */
          margin-bottom: 0mm;
          /* Set your custom bottom margin, e.g., 10mm */
          margin-left: 10mm;
          /* Set your custom left margin, e.g., 0mm (no left margin) */
          margin-right: 10mm;
          /* Set your custom right margin, e.g., 0mm (no right margin) */
      }
      /* Remove the default header and footer generated by the browser */
      thead {
          display: table-header-group;
      }
      tfoot {
          display: table-footer-group;
      }
      /* You can also hide page title (browser default) */
      .title {
          display: none;
      }
  }