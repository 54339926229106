.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
    background-color: #625C5C !important;
    height: 64px;
}

.css-8u39c-MuiTypography-root {
    margin-top: 4px !important;
}

.search_icon {
    position: absolute;
    display: flex !important;
    justify-content: flex-end;
    left: 79%;
    top: 15px;
    color: #979494;
}

.search_input {
    border-radius: 4px;
    height: 37px;
}

.css-h4y409-MuiList-root {
    background: #F9F9F9;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover {
    background-color: #ffff !important;
}

.bg_dashboard {
    color: black;
}

.active {
    background: #ffd8db;
}

.icon_bg {
    /* background: rgba(133, 18, 152, 0.80); */
    color: #dc3545 !important;
}

.css-d9ml5t-MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar {
    width: 1px;
}

.link_tag {
    text-decoration: none;
    color: black;
}

.loader {
    width: fit-content;
    font-weight: bold;
    font-family: monospace;
    font-size: 30px;
    background: linear-gradient(90deg, #000 50%, #0000 0) right/200% 100%;
    animation: l21 2s infinite linear;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* Change 'relative' to 'absolute' */
    top: 50%;
    left: 50%;
    /* Add this line */
    transform: translate(-50%, -50%);
    /* Center horizontally and vertically */
}

.loader::before {
    content: "Loading...";
    color: #0000;
    padding: 0 5px;
    background: inherit;
    background-image: linear-gradient(90deg, #fff 50%, #000 0);
    -webkit-background-clip: text;
    background-clip: text;
}

.close_menu_btn {
    background-color: transparent;
    border: none;
    outline: none;
    display: none;
}

.close_menu_btn svg {
    font-size: 24px;
}

@keyframes l21 {
    100% {
        background-position: left;
    }
}

@media (max-width:600px) {
    .close_menu_btn {
        display: block;
    }
    .css-8u39c-MuiTypography-root {
        font-size: 1rem !important;
    }
}

@media (min-width:1200px) and (max-width:1450px) {
    .admin-logo-sec {
        text-align: center;
    }
    .admin-logo {
        width: 75%;
    }
}