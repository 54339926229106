.edit_cat_card {
    width: 50%;
    padding: 2rem;
    padding-top: 1rem;
    border: none !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.edit_cat_label_column {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.edit_c_cat_input {
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #c3c3c3 !important;
}

.edit_c_cat_input_invalid::placeholder {
    color: red;
}

.select_input_cat {
    border: none;
    border-radius: 0px !important;
    border-bottom: 1px solid #c3c3c3;
}

.selected_img_group {
    display: block;
    text-align: center;
    width: 200px;
}

.remove_btn {
    background-color: #e99f1e !important;
    color: #FFF !important;
    border-radius: 20px !important;
    margin-top: 1rem;
}


/* media */

@media (max-width:1400px) {
    .edit_cat_card {
        width: 65%;
    }
    /* .edit_cat_common_label {
        font-size: 16px;
    } */
}

@media (max-width:1000px) {
    .edit_cat_card {
        width: 100%;
        padding: 0rem;
    }
    .edit_cat_label_column {
        justify-content: flex-start;
    }
}