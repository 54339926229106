.product-tabs-row {
    /* margin-top: 2rem !important; */
    padding: 2rem 4rem !important;
    display: flex;
    justify-content: center;
}

.product-tabs-12 {
    /* display: flex;
    justify-content: center; */
}

.nav-pills-side-tab {
    width: 100%;
    display: -webkit-inline-box !important;
    background-color: #625c5c !important;
    padding: 0rem !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}

.tabs_nav_links .tabs_links.active {
    /* background-color: #ffff!important;
    color: #212121 !important; */
    background-color: #ee3a4b !important;
    color: #fff !important;
    font-weight: 500;
}

.tabs_nav_links .tabs_links {
    border-radius: 0px !important;
    /* color: #212121 !important; */
    color: #fff !important;
    font-weight: 500;
}

.tab-pane {
    background-color: transparent !important;
}