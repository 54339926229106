.loginMainSection {
    height: 100vh;
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    position: relative;
    /* z-index: -1; */
    background-image: url(../assets/images/new_login_bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    /* filter: brightness(.5); */
}

.main-section {
    padding: 0px;
}

.login_right_sec {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    position: relative;
}

.text-orange {
    color: orange;
    font-weight: 600;
}

.loginMap {
    width: 20vw;
}

.text-green {
    color: green;
    font-weight: 600;
}

.map-text {
    text-align: left;
}

.login_right_logo_ {
    text-align: center;
}

.login-text {
    /* text-align: left; */
    color: rgb(147 147 147);
    font-size: 32px;
    font-weight: 400;
}

.login-card {
    background: #ffffff !important;
    padding: 2rem !important;
}

.login-input {
    height: 60px;
    font-weight: 500 !important;
    background: white;
}

.form-control {
    box-shadow: none !important;
    /* border: ; */
}

.user-login-text {
    color: black;
    font-weight: 500;
}

.password-text {
    font-weight: 700;
}

.login-footer {
    position: fixed;
    bottom: 2%;
    left: 3%;
    width: 100%;
}

.footer-text {
    color: gray;
    font-weight: 700;
}

.develope_bottom_text {
    position: absolute;
    bottom: 2%;
    left: 21%;
}

.develope_bottom_text p {
    font-size: 14px;
}

@media (min-width:1450px) and (max-width:1800px) {
    .develope_bottom_text {
        left: 18%;
    }
}

@media (min-width:1300px) and (max-width:1450px) {
    .login-text {
        font-size: 26px;
    }
    .develope_bottom_text {
        left: 10%;
    }
}

@media (max-width:1000px) {
    .login_right_sec {
        height: 100%;
    }
    .develope_bottom_text {
        left: 4%;
    }
    .develope_bottom_text p {
        font-size: 14px;
    }
}